import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import mitt from "mitt";
import { createI18n } from "vue-i18n";

const emitter = mitt();

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import {
  VDataTable,
  VDataTableServer,
  VDataTableVirtual,
} from "vuetify/lib/components/VDataTable";

const vuetify = createVuetify({
  components,
  directives,
  VDataTable,
  VDataTableServer,
  VDataTableVirtual,
});
const defaultLanguage = "kr"; // Set your default language here

// Get the selected language from local storage or use the default language
const selectedLanguage = localStorage.getItem("locale") || defaultLanguage;

const i18n = createI18n({
  locale: selectedLanguage,
  messages: {
    en: require("./locales/en.json"),
    ar: require("./locales/ar.json"),
    kr: require("./locales/kr.json"),
    // Add more language translations as needed
  },
});

const app = createApp(App);
const updateRTLLayout = () => {
  const lang = store.getters.getLang;
  const isRTL = lang === "kr" || lang === "ar";
  document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");

  // Apply RTL class to Vuetify components
  vuetify.rtl = isRTL;
};

// Watch for changes to the language and update the layout
store.watch(
  (state) => state.lang,
  () => {
    updateRTLLayout();
  }
);

// Initial setup
updateRTLLayout();

// Save selected language to local storage on language change
localStorage.setItem("locale", selectedLanguage);

// Set the default language in the store on app initialization
store.commit("setLang", selectedLanguage);
app.use(store);
app.use(vuetify);
app.use(i18n);
app.provide("emitter", emitter);
app.use(router);
app.mount("#app");
