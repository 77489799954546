<template>
  <v-navigation-drawer
    location="right"
    v-model="drawer"
    :rail="rail"
    permanent
    @click="rail = false"
  >
    <!-- Sidebar content -->
    <v-list dense nav>
      <v-list-item
        prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
        :title="getUsername"
        nav
      >
        <template v-slot:append>
          <v-btn
            variant="text"
            icon="mdi-chevron-left"
            @click.stop="rail = !rail"
          ></v-btn>
        </template>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item
        prepend-icon="mdi-view-dashboard"
        :title="$t('dashboard')"
        value="dashboard"
        @click="$router.push('/dashboard')"
      ></v-list-item>
      <v-list-item
        v-if="isSuperAdmin"
        prepend-icon="mdi-account-group"
        :title="$t('accounts')"
        value="home"
        @click="$router.push('/accounts')"
      ></v-list-item>
      <v-list-item
        v-if="!isMedia"
        prepend-icon="mdi-email"
        :title="$t('mailBox')"
        value="mailBox"
        @click="$router.push('/mailbox')"
      >
        <template v-slot:append>
          <v-icon size=""> mdi-bell </v-icon>
          <span class="text-red" v-html="getMailCounter"></span>
        </template>
      </v-list-item>
      <v-list-item
        v-if="!isMedia"
        prepend-icon="mdi-archive"
        :title="$t('sentArchive')"
        value="sentArchive"
        @click="$router.push('/sentArchive')"
      ></v-list-item>
      <v-list-item
        v-if="!isMedia"
        prepend-icon="mdi-archive"
        :title="$t('recevierArchived')"
        value="recevierArchive"
        @click="$router.push('/recevierArchive')"
      ></v-list-item>
      <v-list-item
        v-if="isMedia"
        prepend-icon="mdi-play-network"
        :title="$t('media')"
        value="media"
        @click="$router.push('/media')"
      ></v-list-item>
      <v-list-item
        v-if="isStaff"
        prepend-icon="mdi-chart-bar"
        :title="$t('chart')"
        value="media"
        @click="$router.push('/chartform')"
      >
        <template v-slot:append>
          <v-icon size=""> mdi-bell </v-icon>
          <span class="text-red" v-html="getReportRejected"></span>
        </template>
      </v-list-item>
      <v-list-item
        v-if="isChart || isSuperAdmin"
        :title="$t('reportview')"
        value="reportview"
        @click="$router.push('/reportview')"
        prepend-icon="mdi-eye"
      ></v-list-item>

      <v-list-item
        v-if="isStaff || isSuperAdmin"
        :title="$t('reports_archive')"
        value="reportview"
        @click="$router.push('/reports')"
        prepend-icon="mdi-file-document"
      ></v-list-item>

      <v-list-item class="mt-auto">
        <v-menu open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn color="white" size="small" v-bind="props">
              <v-icon>mdi-translate</v-icon>
              {{ $t("languages") }}
            </v-btn>
            <v-btn class="mx-1" size="small" color="white" @click="logout">{{
              $t("logout")
            }}</v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, index) in languages" :key="index">
              <v-list-item-title @click="changeLocale(index)">{{
                item
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import hasRole from "@/middleware/hasRole";
import axios from "axios";
export default {
  // inject: ["emitter"],
  data() {
    return {
      drawer: true,
      rail: false,
      selectedLanguage: null,
      languages: {
        en: "English",
        ar: "Arabic",
        kr: "Kurdish",
      },
    };
  },
  computed: {
    ...mapGetters([
      "getLang",
      "getUsername",
      "getMailCounter",
      "getReportRejected",
    ]),
    isSuperAdmin() {
      return hasRole.isRole("superadmin");
    },
    isMedia() {
      return hasRole.isRole("media");
    },
    isStaff() {
      return hasRole.isRole("staff");
    },
    isChart() {
      return hasRole.isRole("chart");
    },
    drawerLocation() {
      if (this.getLang === "kr" || this.getLang === "ar") {
        return "right";
      } else {
        return "start";
      }
    },
  },
  mounted() {
    this.$store.dispatch("notification", axios);
    this.$store.dispatch("reportRejected", axios);
    setInterval(() => {
      this.$store.dispatch("notification", axios);
      this.$store.dispatch("reportRejected", axios);
    }, 60000);
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout", this.$router);
    },
    changeLocale(locale) {
      store.commit("setLang", locale);
      this.$i18n.locale = locale;
    },
  },
};
</script>
<style>
/* change drawer color*/
.v-navigation-drawer {
  background-color: #171717 !important;
  color: #ffffff !important;
}
</style>
