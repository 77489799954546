<template>
  <div>
    <app-layout>
      <router-view></router-view>
    </app-layout>
  </div>
</template>

<script>
import AppLayout from "./components/Global/AppLayout.vue";

export default { components: { AppLayout } };
</script>

<style></style>
